import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useToast,
    Stack,
    Heading,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import axios from "axios";
import VIPLevelModal from "./VIPLevelModal";
import VIPBenefits from "./VIPBenefits";
import SubLevelModal from "./SubLevelModal";

export default function VIPLevels() {
    const [vipLevels, setVipLevels] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const toast = useToast();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState("add");
    const [levelData, setLevelData] = useState(null);

    const [isSublevelModalOpen, setSublevelModalOpen] = useState(false);
    const [sublevelData, setSublevelData] = useState(null);
    const [sublevelParentData, setSublevelParentData] = useState(null);

    const handleAddClick = () => {
        setMode("add");
        setLevelData(null);
        setIsModalOpen(true);
    };

    const handleEditClick = (level) => {
        setMode("edit");
        setLevelData(level);
        setIsModalOpen(true);
    };

    useEffect(() => {
        fetchVipLevels();
        fetchVipBenefits();
    }, []);

    const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/admin/vip-levels`;

    const apiRequest = async (method, url, data = null) => {
        try {
            const response = await axios({
                method,
                url: url,
                data: data ? JSON.stringify(data) : {},
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return response.data; // Return the data from the response
        } catch (error) {
            console.error("API error: ", error);
            throw error.response
                ? error.response.data
                : { message: "Network Error" };
        }
    };

    // Fetch VIP levels from API
    const fetchVipLevels = async () => {
        try {
            const response = await apiRequest("get", API_BASE_URL);
            setVipLevels(response.data);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load VIP levels",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchVipBenefits = async () => {
        try {
            const response = await apiRequest(
                "get",
                API_BASE_URL + "/benefits"
            );
            setBenefits(response.data);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to load VIP levels",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = (data, levelId) => {
        // Perform API call or state update
        if (mode === "add") {
            handleCreate(data);
            console.log("Adding new level:", data);
        } else if (mode === "edit") {
            handleUpdate({ levelId, ...data });
        }
    };

    // Update VIP level
    const handleCreate = async (level) => {
        try {
            await apiRequest("post", `${API_BASE_URL}`, level);
            toast({
                title: "Success",
                description: "VIP level added successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            fetchVipLevels(); // Refresh the data after update
        }
    };

    // Update VIP level
    const handleUpdate = async (level) => {
        try {
            await apiRequest("put", `${API_BASE_URL}/${level.levelId}`, level);
            toast({
                title: "Success",
                description: "VIP level updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            fetchVipLevels(); // Refresh the data after update
        }
    };

    // Delete VIP level
    const handleDelete = async (levelId) => {
        try {
            await apiRequest("delete", `${API_BASE_URL}/${levelId}`);
            toast({
                title: "Success",
                description: "VIP level deleted successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            fetchVipLevels(); // Refresh the data after deletion
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete VIP level",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleSublevelSubmit = async (updatedSublevels) => {
        try {
            await apiRequest(
                "put",
                `${API_BASE_URL}/${updatedSublevels.level.levelId}/sublevels`,
                {
                    sublevels: updatedSublevels.sublevels,
                    minXP: updatedSublevels.level.minXP,
                    maxXP: updatedSublevels.level.maxXP,
                }
            );
            toast({
                title: "Success",
                description: "Sublevels updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            fetchVipLevels(); // Refresh the data after update
            setSublevelData([]);
            setSublevelParentData(null);
        }
    };

    const updateBenefits = async (selectedBenefits) => {
        try {
            await apiRequest("put", `${API_BASE_URL}/benefits/update`, {
                benefits: selectedBenefits,
            });
            toast({
                title: "Success",
                description: "Benefits updated successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            fetchVipBenefits(); // Refresh the data after update
        }
    };

    return (
        <>
            <Box p={5}>
                <VIPLevelModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    mode={mode}
                    levelData={levelData}
                    onSubmit={handleSubmit}
                />
                <SubLevelModal
                    isOpen={isSublevelModalOpen}
                    onClose={() => setSublevelModalOpen(false)}
                    levelData={sublevelParentData}
                    sublevelsData={sublevelData}
                    onSubmit={handleSublevelSubmit}
                />
                <Heading>VIP Levels</Heading>
                <Stack alignItems="flex-end" py={4}>
                    <Button
                        colorScheme="blue"
                        width={"fit-content"}
                        size="sm"
                        leftIcon={<AddIcon />}
                        onClick={handleAddClick}
                    >
                        Add Level
                    </Button>
                </Stack>
                <Table size="sm" variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Level Order</Th>
                            <Th>Level Name</Th>
                            <Th>Level Icon</Th>
                            <Th>Min XP</Th>
                            <Th>Max XP</Th>
                            <Th>Casino Points</Th>
                            <Th>Sports Points</Th>
                            <Th>Sub Levels</Th>
                            <Th>Edit</Th>
                            <Th>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {vipLevels.map((level) => (
                            <Tr key={level.levelId}>
                                <Td>{level.levelOrder}</Td>
                                <Td>{level.levelName}</Td>
                                <Td>&nbsp;</Td>
                                <Td>{level.minXP}</Td>
                                <Td>{level.maxXP}</Td>
                                <Td>{level.casinoPoints}</Td>
                                <Td>{level.sportsPoints}</Td>
                                <Td>
                                    <Button
                                        onClick={() => {
                                            setSublevelParentData(level);
                                            setSublevelData(level.sublevels);
                                            setSublevelModalOpen(true);
                                        }}
                                        size="sm"
                                    >
                                        {level.sublevels.length}
                                    </Button>
                                </Td>
                                <Td>
                                    <Button
                                        width={"fit-content"}
                                        leftIcon={<EditIcon />}
                                        size="sm"
                                        colorScheme="gray"
                                        variant="ghost"
                                        onClick={() => handleEditClick(level)}
                                        mr={2}
                                    >
                                        Edit
                                    </Button>
                                </Td>
                                <Td>
                                    <Button
                                        width={"fit-content"}
                                        size="sm"
                                        colorScheme="red"
                                        variant="ghost"
                                        onClick={() =>
                                            handleDelete(level.levelId)
                                        }
                                        mr={2}
                                        leftIcon={<DeleteIcon />}
                                    >
                                        Delete
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

            {benefits &&
                vipLevels &&
                benefits.length > 0 &&
                vipLevels.length > 0 && (
                    <VIPBenefits
                        onChange={(selectedBenefites) => {
                            updateBenefits(selectedBenefites);
                        }}
                        benefits={benefits}
                        vipLevels={vipLevels}
                    />
                )}
        </>
    );
}