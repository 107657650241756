import React, { useState, useEffect } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    Box,
    Heading,
    Button,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const VIPBenefits = ({ vipLevels, benefits, onChange }) => {
    const [selectedBenefits, setSelectedBenefits] = useState({});

    useEffect(() => {
        const initialSelectedBenefits = benefits.reduce((acc, benefit) => {
            acc[benefit.benefit_id] = benefit.vip_plan_ids;
            return acc;
        }, {});
        setSelectedBenefits(initialSelectedBenefits);
    }, [benefits]);

    const handleCheckboxChange = (benefit_id, level_id, checked) => {
        setSelectedBenefits((prevSelected) => {
            const updatedBenefits = { ...prevSelected };
            if (checked) {
                if (!updatedBenefits[benefit_id].includes(level_id)) {
                    updatedBenefits[benefit_id] = [
                        ...updatedBenefits[benefit_id],
                        level_id,
                    ];
                }
            } else {
                updatedBenefits[benefit_id] = updatedBenefits[
                    benefit_id
                ].filter((id) => id !== level_id);
            }
            return updatedBenefits;
        });
    };

    const update = () => {
        if (onChange) {
            onChange(selectedBenefits);
        }
    };

    return (
        <Box p={4}>
            <Heading size="sm" mb={4}>
                VIP Benefits Configuration
            </Heading>
            <Table size="sm" variant="simple">
                <Thead>
                    <Tr>
                        <Th>Benefit</Th>
                        {vipLevels.map((level) => (
                            <Th key={level.levelId}>{level.levelName}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {benefits.map((benefit) => (
                        <Tr key={benefit.benefit_id}>
                            <Td>{benefit.benefit_name}</Td>
                            {vipLevels.map((level) => (
                                <Td
                                    key={`${level.levelId}-${benefit.benefit_id}`}
                                >
                                    <Checkbox
                                        isChecked={
                                            selectedBenefits[
                                                benefit.benefit_id
                                            ]?.includes(level.levelId) || false
                                        }
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                benefit.benefit_id,
                                                level.levelId,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Button
                onClick={update}
                mt={4}
                size={"sm"}
                float={"right"}
                leftIcon={<CheckIcon />}
                width={"150px"}
                colorScheme="green"
            >
                Save Changes
            </Button>
        </Box>
    );
};

export default VIPBenefits;