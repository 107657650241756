import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    IconButton,
    useToast,
} from "@chakra-ui/react";
import { DeleteIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

export default function SubLevelModal(props) {
    const { isOpen, onClose, onSubmit, sublevelsData } = props;
    const [sublevelInput, setSublevelInput] = useState("");
    const [sublevels, setSublevels] = useState([]);
    const levelData = props.levelData;
    const toast = useToast();

    useEffect(() => {
        if (sublevelsData) {
            setSublevels(sublevelsData);
        } else {
            setSublevels([]);
        }
    }, [sublevelsData]);

    if (!levelData) return;
    if (!sublevels) return;

    // Add sublevel to the list
    const addSublevel = () => {
        const xp = parseInt(sublevelInput);
        if (
            isNaN(xp) ||
            sublevels.includes(xp) ||
            xp < levelData.minXP ||
            xp > levelData.maxXP
        ) {
            toast({
                title: "Invalid Input",
                description:
                    "Please enter a valid XP value, should be between Min and Max XP.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            return;
        }
        setSublevels((prev) => [...prev, xp].sort((a, b) => a - b));
        setSublevelInput("");
    };

    // Delete sublevel from the list
    const deleteSublevel = (xp) => {
        setSublevels(sublevels.filter((sub) => sub !== xp));
    };

    // Handle form submission
    const handleSubmit = () => {
        if (sublevels.length < 2) {
            toast({
                title: "Error",
                description:
                    "Please add at least two XP values to create sublevels.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            return;
        }
        onSubmit({ level: levelData, sublevels });
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Edit Sublevels ({levelData.minXP} - {levelData.maxXP})
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4}>
                        <FormControl>
                            <FormLabel>Add Sublevel XP</FormLabel>
                            <Stack direction="row">
                                <Input
                                    size="sm"
                                    type="number"
                                    value={sublevelInput}
                                    onChange={(e) =>
                                        setSublevelInput(e.target.value)
                                    }
                                    placeholder="Enter Min XP value"
                                />
                                <Button
                                    colorScheme="gray"
                                    onClick={addSublevel}
                                    size={"sm"}
                                >
                                    Add
                                </Button>
                            </Stack>
                        </FormControl>

                        {sublevels.length > 0 && (
                            <Table variant="simple" size="sm" mt={4}>
                                <Thead>
                                    <Tr>
                                        <Th>Min XP</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {sublevels.map((xp, index) => (
                                        <Tr key={index}>
                                            <Td>{xp}</Td>
                                            <Td>
                                                <IconButton
                                                    variant={"ghost"}
                                                    colorScheme="red"
                                                    size={"sm"}
                                                    aria-label="Delete"
                                                    icon={<DeleteIcon />}
                                                    onClick={() =>
                                                        deleteSublevel(xp)
                                                    }
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        )}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Stack spacing={4} direction="row">
                        <Button
                            width={"fit-content"}
                            leftIcon={<CloseIcon boxSize={3} />}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            width={"fit-content"}
                            colorScheme="green"
                            mr={3}
                            onClick={handleSubmit}
                            leftIcon={<CheckIcon boxSize={3} />}
                        >
                            Save Changes
                        </Button>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}