import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Select } from '@chakra-ui/react';
import axios from 'axios';
import './QuestManagement.css';

function QuestManagement() {
  const [quests, setQuests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddQuestModalOpen, setIsAddQuestModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [newQuest, setNewQuest] = useState({
    isProgressive: false,
    generalQuestName: '',
    stages: [{ questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }],
  });
  const [editingQuest, setEditingQuest] = useState(null);
  const [isEditQuestModalOpen, setIsEditQuestModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState({ questId: null, isActive: false });
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [selectedDeleteQuestId, setSelectedDeleteQuestId] = useState(null);
  const toast = useToast();

  // Fetch quests from the server
  const fetchQuests = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-quests`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setQuests(response.data.data);
    } catch (err) {
      toast({
        title: 'Failed to fetch quests',
        description: err.response?.data?.message || 'An error occurred while fetching quests.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => { fetchQuests(); }, []);

  // Toggle quest type in the Add Quest modal
  const handleQuestTypeChange = (e) => {
    const isProgressive = e.target.value === 'progressive';
    setNewQuest({
      ...newQuest,
      isProgressive,
      generalQuestName: '',
      stages: isProgressive
        ? [
            { questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }, // Easy
            { questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }, // Medium
            { questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }, // Hard
          ]
        : [{ questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }],
    });
  };

  // Handle input change for stages
  const handleStageInputChange = (index, field, value) => {
    const updatedStages = [...newQuest.stages];
    updatedStages[index][field] = value;
    setNewQuest({ ...newQuest, stages: updatedStages });
  };

  // Submit new quest
  const handleSubmitNewQuest = async () => {
    const adminId = localStorage.getItem('adminId');
    if (!adminId) {
      toast({ title: "Admin data is missing", status: "error", duration: 5000, isClosable: true });
      return;
    }

    const questData = { ...newQuest, adminId };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/quests/add`, questData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data.success) {
        fetchQuests();
        setIsAddQuestModalOpen(false);
        toast({ title: "New quest added.", status: "success", duration: 5000, isClosable: true });

        // Reset the newQuest state to clear the inputs
        setNewQuest({
          isProgressive: false,
          generalQuestName: '',
          stages: [{ questName: '', questDescription: '', rewardPoints: 0, completionCriteria: 0 }],
        });
      } else throw new Error(response.data.message);
    } catch (err) {
      toast({
        title: "Failed to add new quest.",
        description: err.response?.data?.message || "Error adding new quest",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle edit button click
  const handleEditClick = (quest) => {
    const newEditingQuest = {
      ...quest,
      stages: quest.isProgressive
        ? quest.stages.map(stage => ({
            ...stage,
            completionCriteria: stage.completionCriteria?.criteria || 0,
          }))
        : [{
            questName: quest.generalQuestName,
            questDescription: quest.questDescription,
            rewardPoints: quest.rewardPoints,
            completionCriteria: quest.completionCriteria?.criteria || 0,
          }]
    };
    setEditingQuest(newEditingQuest);
    setIsEditQuestModalOpen(true);
  };

  // Function to handle input changes in the Edit Modal
  const handleEditStageInputChange = (index, field, value) => {
    const updatedStages = [...editingQuest.stages];
  
    if (field === 'completionCriteria') {
      updatedStages[index][field] = value; // Store as a number directly for compatibility
    } else {
      updatedStages[index][field] = value;
    }
  
    setEditingQuest({ ...editingQuest, stages: updatedStages });
  };

  // Function to submit the edited quest
  const handleEditQuestSubmit = async () => {
    const questData = {
      questId: editingQuest.questId,
      isProgressive: editingQuest.isProgressive,
      generalQuestName: editingQuest.isProgressive ? editingQuest.generalQuestName : editingQuest.stages[0].questName,
      questDescription: editingQuest.isProgressive ? editingQuest.questDescription : editingQuest.stages[0].questDescription,
      completionCriteria: !editingQuest.isProgressive 
        ? { criteria: editingQuest.stages[0].completionCriteria || 0 } 
        : undefined, // Include only if non-progressive
      rewardPoints: !editingQuest.isProgressive 
        ? editingQuest.stages[0].rewardPoints 
        : undefined, // Include only if non-progressive
      stages: editingQuest.isProgressive
        ? editingQuest.stages.map(stage => ({
            ...stage,
            completionCriteria: { criteria: stage.completionCriteria || 0 },
          }))
        : [], // Only include stages if progressive
      adminId: localStorage.getItem('adminId'),
    };
  
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/quests/edit/${editingQuest.questId}`, questData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data.success) {
        fetchQuests();
        setIsEditQuestModalOpen(false);
        toast({ title: "Quest updated successfully", status: "success", duration: 5000, isClosable: true });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Failed to update quest",
        description: error.response?.data?.message || "Error updating quest",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };  

  // Function to open the confirmation modal
  const openConfirmModal = (questId, isActive) => {
    setSelectedQuest({ questId, isActive });
    setIsConfirmModalOpen(true);
  };

  // Function to confirm activation/deactivation
  const confirmActivationDeactivation = async () => {
    const { questId, isActive } = selectedQuest;
    const adminId = localStorage.getItem('adminId');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/quests/activate-deactivate/${questId}`, {
        isActive: !isActive, // Toggle activation status
        adminId,
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data.success) {
        fetchQuests();
        toast({
          title: `Quest ${isActive ? "deactivated" : "activated"} successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Failed to update quest status",
        description: error.response?.data?.message || "Error updating quest status",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsConfirmModalOpen(false); // Close modal after action
  };

  // Function to open the delete confirmation modal
  const openDeleteConfirmModal = (questId) => {
    setSelectedDeleteQuestId(questId);
    setIsDeleteConfirmModalOpen(true);
  };

  // Function to confirm deletion
  const confirmDeleteQuest = async () => {
    const adminId = localStorage.getItem('adminId');
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/quests/delete/${selectedDeleteQuestId}`, {
        params: { adminId },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data.success) {
        fetchQuests();
        toast({
          title: "Quest deleted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Failed to delete quest",
        description: error.response?.data?.message || "Error deleting quest",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsDeleteConfirmModalOpen(false); // Close modal after action
  };

  // Separate quests into progressive and non-progressive lists
  const progressiveQuests = quests.filter(quest => quest.isProgressive);
  const nonProgressiveQuests = quests.filter(quest => !quest.isProgressive);

  return (
    <ChakraProvider>
      <div className="App">
        <div className="quest-management-container">
          <Input
            type="text"
            placeholder="Search quests..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          />
          <Button colorScheme="blue" onClick={() => setIsAddQuestModalOpen(true)}>➕ Add Quest</Button>

          {/* Non-Progressive Quests Table */}
          <div className="table-container mt-4">
            <h2>Non-Progressive Quests</h2>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Quest ID</th>
                  <th>Quest Name</th>
                  <th>Description</th>
                  <th>Reward Points</th>
                  <th>Completion Criteria</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {nonProgressiveQuests
                  .filter(quest => quest.generalQuestName.toLowerCase().includes(searchQuery))
                  .map(quest => (
                    <tr key={quest.questId}>
                      <td>{quest.questId}</td>
                      <td>{quest.generalQuestName}</td>
                      <td>{quest.questDescription || ""}</td>
                      <td>{quest.rewardPoints || ""}</td>
                      <td>
                        {typeof quest.completionCriteria === "object"
                          ? JSON.stringify(quest.completionCriteria)
                          : quest.completionCriteria || ""}
                      </td>
                      <td style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button colorScheme="green" size="sm" onClick={() => handleEditClick(quest)}>Edit</Button>
                        <Button
                          colorScheme="yellow"
                          size="sm"
                          onClick={() => openConfirmModal(quest.questId, quest.isActive)}
                        >
                          {quest.isActive ? "Deactivate" : "Activate"}
                        </Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => openDeleteConfirmModal(quest.questId)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {/* Progressive Quests Table */}
          <div className="table-container mt-4">
            <h2>Progressive Quests</h2>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Quest ID</th>
                  <th>Quest Name</th>
                  <th>Description</th>
                  <th>Reward Points</th>
                  <th>Completion Criteria</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {progressiveQuests
                  .filter(quest => quest.generalQuestName.toLowerCase().includes(searchQuery))
                  .map(quest => (
                    <React.Fragment key={quest.questId}>
                      <tr>
                        <td>{quest.questId}</td>
                        <td>{quest.generalQuestName}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ display: 'flex', gap: '0.5rem' }}>
                          <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={() => setIsDropdownOpen(prev => ({
                              ...prev,
                              [quest.questId]: !prev[quest.questId],
                            }))}
                          >
                            {isDropdownOpen[quest.questId] ? "Hide Stages" : "Show Stages"}
                          </Button>
                          <Button colorScheme="green" size="sm" onClick={() => handleEditClick(quest)}>Edit</Button>
                          <Button
                            colorScheme="yellow"
                            size="sm"
                            onClick={() => openConfirmModal(quest.questId, quest.isActive)}
                          >
                            {quest.isActive ? "Deactivate" : "Activate"}
                          </Button>
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => openDeleteConfirmModal(quest.questId)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                      {/* Display stages for progressive quests in a sub-table */}
                      {isDropdownOpen[quest.questId] && (
                        <tr>
                          <td colSpan="6">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Stage Name</th>
                                  <th>Description</th>
                                  <th>Reward Points</th>
                                  <th>Completion Criteria</th>
                                </tr>
                              </thead>
                              <tbody>
                                {quest.stages.map((stage, index) => (
                                  <tr key={`${quest.questId}-${index}`}>
                                    <td>{`${["Easy", "Medium", "Hard"][index]}: ${stage.questName || ""}`}</td>
                                    <td>{stage.questDescription || ""}</td>
                                    <td>{stage.rewardPoints || ""}</td>
                                    <td>
                                      {typeof stage.completionCriteria === "object"
                                        ? JSON.stringify(stage.completionCriteria || "")
                                        : stage.completionCriteria || ""}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>  

          {/* Add Quest Modal */}
          <Modal isOpen={isAddQuestModalOpen} onClose={() => setIsAddQuestModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Quest</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel>Quest Type</FormLabel>
                  <Select onChange={handleQuestTypeChange}>
                    <option value="non-progressive">Non-Progressive</option>
                    <option value="progressive">Progressive</option>
                  </Select>
                </FormControl>

                {/* Display quest name input based on quest type */}
                {newQuest.isProgressive ? (
                  <FormControl mt={4}>
                    <FormLabel>General Quest Name</FormLabel>
                    <Input
                      value={newQuest.generalQuestName}
                      onChange={(e) => setNewQuest({ ...newQuest, generalQuestName: e.target.value })}
                    />
                  </FormControl>
                ) : (
                  <FormControl mt={4}>
                    <FormLabel>Quest Name</FormLabel>
                    <Input
                      value={newQuest.stages[0].questName}
                      onChange={(e) => handleStageInputChange(0, 'questName', e.target.value)}
                    />
                  </FormControl>
                )}

                {/* Stages input for progressive quest or single input for non-progressive */}
                {newQuest.isProgressive
                  ? newQuest.stages.map((stage, index) => (
                      <div key={index}>
                        <FormControl mt={4}>
                          <FormLabel>Quest Name ({['Easy', 'Medium', 'Hard'][index]})</FormLabel>
                          <Input
                            value={stage.questName}
                            onChange={(e) => handleStageInputChange(index, 'questName', e.target.value)}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Quest Description</FormLabel>
                          <Input
                            value={stage.questDescription}
                            onChange={(e) => handleStageInputChange(index, 'questDescription', e.target.value)}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Reward Points</FormLabel>
                          <Input
                            type="number"
                            value={stage.rewardPoints}
                            onChange={(e) => handleStageInputChange(index, 'rewardPoints', parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                        <FormLabel>Completion Criteria</FormLabel>
                          <Input
                            type="number"
                            value={newQuest.stages[0].completionCriteria}
                            onChange={(e) => handleStageInputChange(0, 'completionCriteria', parseInt(e.target.value, 10))}
                          />
                        </FormControl>
                      </div>
                    ))
                  : (
                    <div>
                      <FormControl mt={4}>
                        <FormLabel>Quest Description</FormLabel>
                        <Input
                          value={newQuest.stages[0].questDescription}
                          onChange={(e) => handleStageInputChange(0, 'questDescription', e.target.value)}
                        />
                      </FormControl>
                      <FormControl mt={4}>
                        <FormLabel>Reward Points</FormLabel>
                        <Input
                          type="number"
                          value={newQuest.stages[0].rewardPoints}
                          onChange={(e) => handleStageInputChange(0, 'rewardPoints', parseFloat(e.target.value))}
                        />
                      </FormControl>
                      <FormControl mt={4}>
                        <FormLabel>Completion Criteria</FormLabel>
                        <Input
                          type="number"
                          value={newQuest.stages[0].completionCriteria}
                          onChange={(e) => handleStageInputChange(0, 'completionCriteria', parseInt(e.target.value, 10))}
                        />
                      </FormControl>
                    </div>
                  )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={handleSubmitNewQuest}>Save</Button>
                <Button onClick={() => setIsAddQuestModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Edit Quest Modal */}
          <Modal isOpen={isEditQuestModalOpen} onClose={() => setIsEditQuestModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit Quest</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {editingQuest && (
                  <>
                    {editingQuest.isProgressive ? (
                      <>
                        {/* Fields for Progressive Quest */}
                        <FormControl>
                          <FormLabel>General Quest Name</FormLabel>
                          <Input
                            value={editingQuest.generalQuestName}
                            onChange={(e) => setEditingQuest({ ...editingQuest, generalQuestName: e.target.value })}
                          />
                        </FormControl>
                        
                        {/* Render each stage for progressive quests */}
                        {editingQuest.stages.map((stage, index) => (
                          <div key={index}>
                            <FormControl mt={4}>
                              <FormLabel>Quest Name ({['Easy', 'Medium', 'Hard'][index]})</FormLabel>
                              <Input
                                value={stage.questName}
                                onChange={(e) => handleEditStageInputChange(index, 'questName', e.target.value)}
                              />
                            </FormControl>
                            <FormControl mt={4}>
                              <FormLabel>Quest Description</FormLabel>
                              <Input
                                value={stage.questDescription}
                                onChange={(e) => handleEditStageInputChange(index, 'questDescription', e.target.value)}
                              />
                            </FormControl>
                            <FormControl mt={4}>
                              <FormLabel>Reward Points</FormLabel>
                              <Input
                                type="number"
                                value={stage.rewardPoints}
                                onChange={(e) => handleEditStageInputChange(index, 'rewardPoints', parseFloat(e.target.value))}
                              />
                            </FormControl>
                            <FormControl mt={4}>
                              <FormLabel>Completion Criteria</FormLabel>
                              <Input
                                type="number"
                                value={stage.completionCriteria || 0}
                                onChange={(e) => handleEditStageInputChange(index, 'completionCriteria', parseInt(e.target.value, 10))}
                              />
                            </FormControl>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* Fields for Non-Progressive Quest */}
                        <FormControl>
                          <FormLabel>Quest Name</FormLabel>
                          <Input
                            value={editingQuest.stages[0].questName}
                            onChange={(e) => handleEditStageInputChange(0, 'questName', e.target.value)}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Quest Description</FormLabel>
                          <Input
                            value={editingQuest.stages[0].questDescription}
                            onChange={(e) => handleEditStageInputChange(0, 'questDescription', e.target.value)}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Reward Points</FormLabel>
                          <Input
                            type="number"
                            value={editingQuest.stages[0].rewardPoints}
                            onChange={(e) => handleEditStageInputChange(0, 'rewardPoints', parseFloat(e.target.value))}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Completion Criteria</FormLabel>
                          <Input
                            type="number"
                            value={editingQuest.stages[0].completionCriteria || 0}
                            onChange={(e) => handleEditStageInputChange(0, 'completionCriteria', parseInt(e.target.value, 10))}
                          />
                        </FormControl>
                      </>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={handleEditQuestSubmit}>Save</Button>
                <Button onClick={() => setIsEditQuestModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Activate/Deactivate Quest Modal */}
          <Modal isOpen={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm {selectedQuest.isActive ? "Deactivation" : "Activation"}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to {selectedQuest.isActive ? "deactivate" : "activate"} this quest?
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={confirmActivationDeactivation}>
                  Yes
                </Button>
                <Button variant="ghost" onClick={() => setIsConfirmModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Delete Quest Modal */}
          <Modal isOpen={isDeleteConfirmModalOpen} onClose={() => setIsDeleteConfirmModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Deletion</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to delete this quest?
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={confirmDeleteQuest}>
                  Yes
                </Button>
                <Button variant="ghost" onClick={() => setIsDeleteConfirmModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default QuestManagement;
