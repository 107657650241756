import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast } from '@chakra-ui/react';
import axios from 'axios';
// import Login from './Login';
import { io } from 'socket.io-client';
import { format } from 'date-fns';
// import { jwtDecode } from 'jwt-decode';
import './Dashboard.css';

// Import for date picker
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Drop down for user
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function UserDashboard() {
  const [users, setUsers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const isAlreadyLoggedIn = sessionStorage.getItem('isLoggedIn');
    return isAlreadyLoggedIn === 'true';
  });
  const [socket, setSocket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalPoints, setModalPoints] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isBanConfirmModalOpen, setIsBanConfirmModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const toast = useToast();

  // Filter Date Range
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Selected user state
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);

  const [isConfirmResetModalOpen, setIsConfirmResetModalOpen] = useState(false);
  const [isResetEmailModalOpen, setIsResetEmailModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  // const [newPassword, setNewPassword] = useState('');

  const [betData, setBetData] = useState([]);
  const [isBetHistoryModalOpen, setIsBetHistoryModalOpen] = useState(false);

  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [currencyName, setCurrencyName] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');

  const [selectedCurrency, setSelectedCurrency] = useState('Points');
  const [currencies, setCurrencies] = useState([]);
  const [currencyMap, setCurrencyMap] = useState({});

  const [modalSelectedCurrency, setModalSelectedCurrency] = useState('');
  // const [currencyNameToIdMap, setCurrencyNameToIdMap] = useState({});

  const [isWalletsModalOpen, setIsWalletsModalOpen] = useState(false);
  const [selectedUserWallets, setSelectedUserWallets] = useState([]);

  const [isEffectiveTurnoverModalOpen, setIsEffectiveTurnoverModalOpen] = useState(false);
  const [effectiveTurnoverData, setEffectiveTurnoverData] = useState([]);  
  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [isSearching, setIsSearching] = useState(false);


  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`);

    newSocket.on('newUser', (newUserData) => {
      setUsers((prevUsers) => {
        // Ensure the user does not already exist in the state
        if (!prevUsers.some(user => Number(user.userId) === Number(newUserData._id))) {
          return [...prevUsers, newUserData];
        }
        return prevUsers;
      });
    });

    newSocket.on('pointsUpdatedAdmin', (data) => {
      setUsers((prevUsers) => prevUsers.map(user =>
        Number(user.userId) === Number(data.userId) ? { ...user, points: data.points } : user
      ));
    });

    newSocket.on('creditsUpdatedAdmin', (data) => {
      console.log('Credits Update Received:', data);
      // Handle the credits update
      setUsers((prevUsers) => prevUsers.map(user => {
        if (Number(user.userId) === Number(data.userId)) {
          // Find the wallet with the matching currencyId and update its balance
          const updatedWallets = user.wallets.map(wallet =>
            wallet.currencyId === data.currencyId ? { ...wallet, balance: data.newBalance } : wallet
          );
          return { ...user, wallets: updatedWallets };
        }
        return user;
      }));
    });

    newSocket.on('banStatusUpdated', (data) => {
      setUsers((prevUsers) => prevUsers.map(user =>
        Number(user.userId) === Number(data.userId) ? { ...user, banned: data.banned } : user
      ));
    });

    newSocket.on('newCurrencyAdded', (newCurrency) => {
      setCurrencies(prevCurrencies => {
        const updatedCurrencies = [...prevCurrencies, newCurrency];
        console.log("Updated currencies:", updatedCurrencies); // Log to inspect the updated state
        return updatedCurrencies;
    });

      setCurrencyMap(prevCurrencyMap => ({
        ...prevCurrencyMap,
        [newCurrency.currencyName]: newCurrency.currencyId
      }));
    });

    setSocket(newSocket);
    return () => {
      newSocket.off('newUser');
      newSocket.off('pointsUpdatedAdmin');
      newSocket.off('banStatusUpdated');
      newSocket.off('creditsUpdatedAdmin');
      newSocket.off('newCurrencyAdded');
      newSocket.close();
    };
  }, [setSocket, toast]);


  useEffect(() => {
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('token='))
      ?.split('=')[1];

    if (token) {
      // Perform verification with the token if necessary and set login state
      setIsLoggedIn(true);
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-users`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.success) {
          setUsers(response.data.data); // Use 'data.data' to access the users array
          console.log(response.data.data)
        } else {
          // Use a toast to display the error message
          toast({
            title: "Failed to fetch users.",
            description: response.data.error?.message || "An unexpected error occurred.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        // Use a toast to display the error
        toast({
          title: "Network error.",
          description: "Unable to fetch users. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    };

    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-currencies`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.success) {
          setCurrencies(response.data.data);

          const newCurrencyMap = {};
          response.data.data.forEach(currency => {
            newCurrencyMap[currency.currencyName] = currency.currencyId;
          });

          setCurrencyMap(newCurrencyMap);
        } else {
          toast({
            title: "Failed to fetch currencies.",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Error fetching currencies",
          description: err.toString(),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };


    fetchUsers();
    fetchCurrencies();
  }, [socket, toast]);

  const handleAddBalance = async (user) => {
    setModalAction('addBalance');
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleRemoveBalance = async (user) => {
    setModalAction('removeBalance');
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const openResetEmailModal = (user) => {
    setSelectedUser(user);
    setResetEmail(user.email || '');  // Assuming user has an 'email' attribute
    setIsResetEmailModalOpen(true);
  };

  const openBanConfirmModal = (user) => {
    setSelectedUser(user);
    setModalAction(user.banned ? 'unban' : 'ban');
    setIsBanConfirmModalOpen(true);
  };

  const handleModalSubmit = async () => {
    setIsModalOpen(false);
    const adminId = localStorage.getItem('adminId');
    const amount = Math.abs(parseFloat(modalPoints)); // Ensure amount is positive
    const currencyId = currencyMap[modalSelectedCurrency];
    let isPoints = currencyId === undefined;
    const transactionType = modalAction === 'addBalance' ? 'add' : 'subtract';
    const source = 'admin-action';

    if (!adminId || !selectedUser || isNaN(amount)) {
      console.error('Required data is missing');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-balance`,
        {
          userId: selectedUser.userId,
          isPoints,
          currencyId,
          transactionType,
          amount,
          source,
          adminId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

      if (response.data.success) {
        const message = response.data.message;
        setSuccessMessage(message);
      } else {
        alert(response.data.message || 'An error occurred');
      }

      setTimeout(() => setSuccessMessage(''), 2000); // Clear success message after display
    } catch (err) {
      console.error('Error updating balance', err);
      alert(err.response?.data?.message || 'An unknown error occurred');
    }

    // Clear modal state
    setModalPoints('');
    setModalSelectedCurrency('');
    setSelectedUser(null);
  };

  const handleBanUnbanUser = async () => {
    if (!selectedUser) return;
    const adminId = localStorage.getItem('adminId');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/ban/${selectedUser.userId}`,
        {
          ban: modalAction === 'ban', adminId
        }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setSuccessMessage(response.data.message);

        // Update users state to reflect ban/unban status
        setUsers(prevUsers => prevUsers.map(user =>
          user.userId === selectedUser.userId ? { ...user, banned: response.data.banned } : user
        ));
      } else {
        // Handle failure case with response message
        alert(response.data.message);
      }

      // Display success message for 2 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);

      // Clear modal state after action
      setSelectedUser(null);
      setIsBanConfirmModalOpen(false);
    } catch (err) {
      console.error('Error changing ban status', err);
      // Use a more structured error response if available
      const errorMessage = err.response?.data?.message || 'Failed to change ban status';
      alert(errorMessage);
    }
  };

  const openConfirmResetModal = (user) => {
    console.log('here user', user);
    setSelectedUser(user);
    setIsConfirmResetModalOpen(true);
  };

  const updateEmail = async () => {
    const adminId = localStorage.getItem('adminId');
    if (!/\S+@\S+\.\S+/.test(resetEmail)) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/reset-email/${selectedUser.userId}`, 
      {
        adminId: adminId,
        newEmail: resetEmail
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.data.success) {
        toast({
          title: "Email Updated",
          description: `Email has been successfully updated for ${selectedUser.username}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsResetEmailModalOpen(false);
        // Update local state or re-fetch user data if necessary
      } else {
        toast({
          title: "Failed to update email",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Update failed",
        description: error.toString(),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConfirmReset = async () => {
    setIsConfirmResetModalOpen(false);
    const adminId = localStorage.getItem('adminId');

    if (!adminId || !selectedUser) {
      toast({
        title: "Error",
        description: "Admin data or selected user is missing.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/reset-password/${selectedUser.userId}`,
        { adminId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
      
      if (response.data.data.tempPassword) {
        console.log('temp password is ',response.data.data.tempPassword);
        // Consider using a modal or a more secure method to display the temporary password
        toast({
          title: "Password Reset Successfully",
          description: `The temporary password for ${selectedUser.username} is: ${response.data.data.tempPassword}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Password Reset",
          description: "Password reset but temporary password not received.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error Resetting Password",
        description: `An error occurred while resetting the password for ${selectedUser.username}.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'PPpp');
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  // Selected user modal
  const handleViewUserDetails = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/user-details/${userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
      setSelectedUserDetails(response.data.data); // Adjusted to match structured response
      setIsUserDetailsModalOpen(true);
    } catch (err) {
      console.error('Error fetching user details', err);
      toast({
        title: 'Failed to fetch user details',
        description: err.response?.data?.message || 'An unexpected error occurred',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleUserCheckboxChange = (userId, isChecked) => {
    setSelectedUsers(prevSelectedUsers => {
      const newSelectedUsers = new Set(prevSelectedUsers);
      if (isChecked) {
        newSelectedUsers.add(userId);
      } else {
        newSelectedUsers.delete(userId);
      }
      return newSelectedUsers;
    });
  };

  const handleSelectAllCheckboxChange = (isChecked) => {
    // Apply the same filter logic as used in the table rendering
    const filteredUsers = users.filter((user) => {
      const usernameMatch = user.username.toLowerCase().includes(searchQuery);
      const joinDate = new Date(user.timeJoined);
      const adjustedEndDate = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) : null;
      const inDateRange = (!startDate || joinDate >= startDate) && (!adjustedEndDate || joinDate <= adjustedEndDate);
      return usernameMatch && inDateRange;
    });

    // Update the selectedUsers state based on the filtered users
    const updatedSelectedUsers = new Set();
    if (isChecked) {
      filteredUsers.forEach((user) => updatedSelectedUsers.add(user.userId));
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleBulkAddPoints = async () => {

    if (selectedUsers.size === 0) {
      toast({
        title: "No Users Selected",
        description: "Please select at least one user.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setModalAction('bulkAdd');
    setIsModalOpen(true);
  };

  const fetchBetData = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/user-bet-history/${userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
      setBetData(response.data.data); // Adjusted to match structured response
      setCurrentPage(1); // Reset to page 1
      setIsBetHistoryModalOpen(true); // Open the modal after fetching data
    } catch (err) {
      console.error('Error fetching bet data', err);
      toast({
        title: 'Failed to fetch bet history',
        description: err.response?.data?.message || 'An unexpected error occurred while fetching the bet history.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchEffectiveTurnover = async (userId, startFilterDate, endFilterDate) => {
    try {
      if (!startFilterDate || !endFilterDate) {
        toast({
          title: "Error",
          description: "Please select both start and end dates.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const user = users.find(u => u.userId === userId);
      setSelectedUser(user);
  
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/effective-turnover/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          startDate: startFilterDate.toISOString(),
          endDate: endFilterDate.toISOString()
        }
      });
  
      if (response.data.success) {
        if (response.data.data.length === 0) {
          // Display blue toast if no data is found
          toast({
            title: "No Data Found",
            description: response.data.message,
            status: "info", // Use "info" for a blue toast
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        } else {
          setEffectiveTurnoverData(response.data.data);
          setIsEffectiveTurnoverModalOpen(true);
          toast({
            title: "Effective Turnover Retrieved",
            description: "Effective turnover data successfully fetched.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.toString(),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };  

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = betData.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(betData.length / itemsPerPage);
    const maxPageButtons = 10;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = startPage + maxPageButtons - 1;
  
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav>
        <ul className='pagination'>
          {currentPage > 1 && (
            <li className='page-item'>
              <a onClick={(e) => { e.preventDefault(); setCurrentPage(currentPage - 1); }} href='!#' className='page-link'>
                Previous
              </a>
            </li>
          )}
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a onClick={(e) => { e.preventDefault(); setCurrentPage(number); }} href='!#' className='page-link'>
                {number}
              </a>
            </li>
          ))}
          {currentPage < totalPages && (
            <li className='page-item'>
              <a onClick={(e) => { e.preventDefault(); setCurrentPage(currentPage + 1); }} href='!#' className='page-link'>
                Next
              </a>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  return (
    <ChakraProvider>
      <div className="App">

        {/* Success Message */}
        {successMessage && (
          <div className="success-message alert alert-success fixed-top">
            {successMessage}
          </div>
        )}


        {/* {!isLoggedIn ? (
          <Login handleLogin={handleLogin} />
        ) : (*/}
        <>
          {/* Modal Component */}
          <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {modalAction.includes('Balance') ? `${modalAction === 'addBalance' ? '➕' : '➖'} Adjust Balance for ${selectedUser?.username}` : ''}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleModalSubmit();
              }}>
                {/* Currency Dropdown */}
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <Form.Control as="select" value={modalSelectedCurrency} onChange={(e) => setModalSelectedCurrency(e.target.value)}>
                    <option value="Points">Points</option>
                    {currencies.map((currency, index) => (
                      <option key={index} value={currency.currencyName}>
                        {currency.currencyName}
                      </option>
                    ))}
                  </Form.Control>

                </Form.Group>
                {/* Amount Input */}
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={modalPoints}
                    onChange={(e) => setModalPoints(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>


          {/* Ban Modal Component */}
          {isBanConfirmModalOpen && selectedUser && (
            <Modal show={isBanConfirmModalOpen} onHide={() => setIsBanConfirmModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Are you sure you want to {modalAction === 'ban' ? '❌' : '✔️'} {modalAction} {selectedUser?.username}?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Any additional content or descriptions can be placed here */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsBanConfirmModalOpen(false)}>
                  No
                </Button>
                <Button
                  variant={modalAction === 'ban' ? 'danger' : 'success'}
                  onClick={handleBanUnbanUser}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {isEffectiveTurnoverModalOpen && selectedUser && (
            <Modal size="lg" show={isEffectiveTurnoverModalOpen} onHide={() => {
              setEffectiveTurnoverData([]);
              setIsSearching(false);
              setStartFilterDate(null);
              setEndFilterDate(null);
              setIsEffectiveTurnoverModalOpen(false);
            }}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Effective Turnover for {selectedUser.username}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!isSearching ? (
                  <div>
                    {/* Date Pickers */}
                    <ReactDatePicker
                      selected={startFilterDate}
                      onChange={(date) => setStartFilterDate(date)}
                      selectsStart
                      startDate={startFilterDate}
                      endDate={endFilterDate}
                      isClearable
                      placeholderText="Start Filter Date"
                    />

                    <ReactDatePicker
                      selected={endFilterDate}
                      onChange={(date) => setEndFilterDate(date)}
                      selectsEnd
                      startDate={startFilterDate}
                      endDate={endFilterDate}
                      minDate={startFilterDate}
                      isClearable
                      placeholderText="End Filter Date"
                    />

                    <Button
                      variant="primary"
                      onClick={() => {
                        fetchEffectiveTurnover(selectedUser.userId, startFilterDate, endFilterDate);
                        setIsSearching(true);
                      }}
                    >
                      Search
                    </Button>
                  </div>
                ) : (
                  <div>
                    {/* Table with effective turnover data */}
                    {effectiveTurnoverData.length > 0 ? (
                      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Game Name</th>
                              <th>Game Code</th>
                              <th>RTP (%)</th>
                              <th>Total Turnover</th>
                              <th>Effective Turnover</th>
                            </tr>
                          </thead>
                          <tbody>
                            {effectiveTurnoverData.map((turnover, index) => (
                              <tr key={index}>
                                <td>{turnover.gameName}</td>
                                <td>{turnover.gameCode}</td>
                                <td>{turnover.rtpValue}%</td>
                                <td>${parseFloat(turnover.totalTurnover).toFixed(2)}</td>
                                <td>${parseFloat(turnover.effectiveTurnover).toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No data available.</p>
                    )}

                    {/* Summary Section */}
                    {effectiveTurnoverData.length > 0 && (
                      <div className="summary-section mt-3">
                        <h5>Summary</h5>
                        <p><strong>Total Effective Turnover:</strong> ${effectiveTurnoverData.reduce((acc, turnover) => acc + parseFloat(turnover.effectiveTurnover), 0).toFixed(2)}</p>
                      </div>
                    )}

                    {/* Reset Button */}
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setEffectiveTurnoverData([]);
                        setIsSearching(false);
                        setStartFilterDate(null);
                        setEndFilterDate(null);
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                setEffectiveTurnoverData([]);
                setIsSearching(false);
                setStartFilterDate(null);
                setEndFilterDate(null);
                setIsEffectiveTurnoverModalOpen(false);
              }}>
                Close
              </Button>
              </Modal.Footer>
            </Modal>
          )}

          {/* User Details Modal Component */}
          {isUserDetailsModalOpen && (
            <Modal show={isUserDetailsModalOpen} onHide={() => setIsUserDetailsModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Display user details here */}
                <h2>User Details</h2>
                <p><strong>User Id:</strong> {selectedUserDetails.userId}</p>
                <p><strong>Username:</strong> {selectedUserDetails.username}</p>
                <p><strong>Points:</strong> {selectedUserDetails.points}</p>
                <p><strong>Banned:</strong> {selectedUserDetails.banned?'Yes':'No'}</p>
                <p><strong>Time Joined:</strong> {selectedUserDetails.timeJoined}</p>
                <p><strong>Wallet Address:</strong> {selectedUserDetails.wallet_address?selectedUserDetails.walletAddress:'No Wallet Address Yet'}</p>
                <p><strong>Roles:</strong> {selectedUserDetails.roles}</p>
                <p><strong>Upline (Referrer):</strong> {selectedUserDetails.upline}</p>
              </Modal.Body>
            </Modal>
          )}

          {isConfirmResetModalOpen && (
            <Modal show={isConfirmResetModalOpen} onHide={() => setIsConfirmResetModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Password Reset</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to reset the password for {selectedUser?.username}?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleConfirmReset}>Yes</Button>
                <Button variant="secondary" onClick={() => setIsConfirmResetModalOpen(false)}>No</Button>
              </Modal.Footer>
            </Modal>
          )}

          {isResetEmailModalOpen && (
            <Modal show={isResetEmailModalOpen} onHide={() => setIsResetEmailModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Reset Email for {selectedUser?.username}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  updateEmail();
                }}>
                  <Form.Group>
                    <Form.Label>New Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Update Email
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          )}

          {isBetHistoryModalOpen && (
            <Modal className='custom-modal-size custom-modal-position' show={isBetHistoryModalOpen} onHide={() => setIsBetHistoryModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Transaction History</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {currentItems.length > 0 ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Settled Bet ID</th>
                          <th>Place Bet ID</th>
                          <th>Result Type</th>
                          <th>Total Stake</th>
                          <th>Win Amount</th>
                          <th>TurnOver</th>
                          <th>Tax</th>
                          <th>Commission</th>
                          <th>Jackpot Amount</th>
                          <th>Is Freespin</th>
                          <th>Is End Round</th>
                          <th>Return To Wallet</th>
                          <th>Settlement Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((bet, index) => (
                          <tr key={index}>
                            <td>{bet.settledBetId}</td>
                            <td>{bet.placeBetId}</td>
                            <td>{bet.resultType}</td>
                            <td>${parseFloat(bet.totalStake).toFixed(2)}</td>
                            <td>${parseFloat(bet.winAmount).toFixed(2)}</td>
                            <td>${parseFloat(bet.turnOver).toFixed(2)}</td>
                            <td>${bet.tax?parseFloat(bet.tax).toFixed(2):'0.0'}</td>
                            <td>${bet.commission?parseFloat(bet.commission).toFixed(2):'0.0'}</td>
                            <td>${parseFloat(bet.jackpotAmount).toFixed(2)}</td>
                            <td>{bet.isFreespin ? 'Yes' : 'No'}</td>
                            <td>{bet.isEndRound ? 'Yes' : 'No'}</td>
                            <td>${parseFloat(bet.returnToWallet).toFixed(2)}</td>
                            <td>{new Date(bet.settlementTime).toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {renderPageNumbers()}
                  </>
                ) : (
                  <p>No transaction history available.</p>
                )}
              </Modal.Body>
            </Modal>
          )}


          {/* {isBetHistoryModalOpen && (
            <Modal className='custom-modal-size custom-modal-position' show={isBetHistoryModalOpen} onHide={() => setIsBetHistoryModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Transaction History</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {currentItems.length > 0 ? (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Settled Bet ID</th>
                          <th>Place Bet ID</th>
                          <th>Win/Loss</th>
                          <th>Total Stake</th>
                          <th>Win Amount</th>
                          <th>TurnOver</th>
                          <th>Tax</th>
                          <th>Commission</th>
                          <th>Settlement Time</th>
                          <th>Return To Wallet</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((bet, index) => (
                          <tr key={index}>
                            <td>{bet.settled_bet_id}</td>
                            <td>{bet.place_bet_id}</td>
                            <td>{bet.win_loss}</td>
                            <td>${parseFloat(bet.totalStake).toFixed(2)}</td>
                            <td>${parseFloat(bet.winAmount).toFixed(2)}</td>
                            <td>${parseFloat(bet.turnOver).toFixed(2)}</td>
                            <td>${parseFloat(bet.tax).toFixed(2)}</td>
                            <td>${parseFloat(bet.commission).toFixed(2)}</td>
                            <td>{new Date(bet.settlement_time).toLocaleString()}</td>
                            <td>${parseFloat(bet.returnToWallet).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {renderPageNumbers()}
                  </>
                ) : (
                  <p>No transaction history available.</p>
                )}
              </Modal.Body>
            </Modal>
          )} */}

          {isWalletsModalOpen && (
            <Modal show={isWalletsModalOpen} onHide={() => setIsWalletsModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Wallet Balances</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  {selectedUserWallets
                    .filter(wallet => !wallet.isDeleted) // Assuming `isDeleted` flag is available in your wallet object
                    .map((wallet, index) => (
                      <li key={index}>
                        {wallet.currencyName}: {wallet.balance}
                      </li>
                    ))}
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsWalletsModalOpen(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          <div className="dashboard-container">
            <div className="dashboard-header d-flex justify-content-between align-items-center">
              <h1 className='admin-dashboard'>$BANG Admin Dashboard</h1>
              {/* <button className="logout-btn btn btn-secondary w-25 p-3" onClick={handleLogout}>Logout</button> */}
            </div>

            <div className="search-bar-container d-flex align-items-center justify-content-center">
              <input
                type="text"
                className="search-bar form-control"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
              />


              {/* React date picker */}
              <div className="custom-datepicker d-flex">

                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  placeholderText='Start Date'
                />
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  isClearable
                  placeholderText='End Date'
                />
              </div>

            </div>

            <div className="bulk-actions">
              <button className="btn btn-primary" onClick={() => handleBulkAddPoints()}>➕ Bulk add points for selected users</button>
            </div>
            <br></br>
            <div className="table-container mx-auto overflow-auto border rounded">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectedUsers.size === users.filter(user => user.username.toLowerCase().includes(searchQuery)).length}
                        onChange={(e) => handleSelectAllCheckboxChange(e.target.checked)}
                      />
                    </th>
                    <th>Index</th>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Time Joined</th>
                    <th>
                      <select
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                      >
                        <option value="Points">Points</option>
                        {currencies.map((currency, index) => (
                          <option key={index} value={currency.currencyName}>
                            {currency.currencyName}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((user) => {
                      // Existing filter logic
                      const usernameMatch = user.username.toLowerCase().includes(searchQuery);
                      const joinDate = new Date(user.timeJoined);
                      const adjustedEndDate = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) : null;
                      const inDateRange = (!startDate || joinDate >= startDate) && (!adjustedEndDate || joinDate <= adjustedEndDate);

                      return usernameMatch && inDateRange;
                    })
                    .map((user, index) => {
                      let displayValue;
                      if (selectedCurrency === "Points") {
                        displayValue = user.points;
                      } else {
                        // Find the wallet by comparing the selectedCurrency with wallet's currencyName
                        const wallet = user.wallets.find(w => w.currencyName === selectedCurrency);
                        displayValue = wallet ? wallet.balance : 'N/A';
                      }
                      return (
                        <tr key={user.userId}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedUsers.has(user.userId)}
                              onChange={(e) => handleUserCheckboxChange(user.userId, e.target.checked)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{user.userId}</td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ flex: 1, marginRight: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {user.username}
                              </div>
                              <Dropdown align="end">
                                <Dropdown.Toggle as={Button} variant="outline-secondary" size='sm' className="ml-2">
                                  <i className="fas fa-ellipsis-v"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => {
                                    setSelectedUser(user);
                                    setIsEffectiveTurnoverModalOpen(true);
                                  }}>View Effective Turnover</Dropdown.Item>
                                  <Dropdown.Item onClick={() => fetchBetData(user.userId)}>Transaction History</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleViewUserDetails(user.userId)}>User Details</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                          <td>{formatDate(user.timeJoined)}</td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span>{displayValue}</span>
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => {
                                  setSelectedUserWallets(user.wallets);
                                  setIsWalletsModalOpen(true);
                                }}
                                style={{ marginLeft: '10px' }}
                              >
                                View All
                              </Button>
                            </div>
                          </td>

                          <td>
                            <div className="action-buttons">
                              <button className='btn btn-primary mr-1' onClick={() => handleAddBalance(user)}>➕ Add Balance</button>
                              <button className='btn btn-primary mr-1' onClick={() => handleRemoveBalance(user)}>➖ Remove Balance</button>
                              <button className='ban-button' onClick={() => openBanConfirmModal(user)}>
                                {user.banned ? '✔️ Unban' : '❌ Ban'}
                              </button>
                              <button className='btn btn-primary mr-1' onClick={() => openConfirmResetModal(user)}  disabled={user.isGoogleLogin}>Reset PW</button>
                              <button className='btn btn-primary mr-1' onClick={() => openResetEmailModal(user)}>Reset Email</button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
        {/* )} */}
      </div>
    </ChakraProvider>
  );
}

export default UserDashboard;

