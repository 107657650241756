import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Link,
  useToast,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker'; // Import the DatePicker
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import axios from 'axios';

function AffiliateReport() {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [formData, setFormData] = useState({
    fromDate: '',
    toDate: '',
  });
  const toast = useToast();

  const isMonday = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return dayOfWeek === 1; // Monday = 1 (local time)
  };

  const handleInputChange = (date, name) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleSubmit = async () => {
    const { fromDate, toDate } = formData;
  
    if (!fromDate || !toDate) {
      toast({
        title: "Validation Error",
        description: "Both 'From Date' and 'To Date' must be selected.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const fromDateTime = new Date(fromDate).toISOString();
    const toDateTime = new Date(toDate).toISOString();
  
    if (new Date(fromDate) >= new Date(toDate)) {
      toast({
        title: "Validation Error",
        description: "'From Date' must be earlier than 'To Date'.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/affiliate-report`, {
        fromDate: fromDateTime,
        toDate: toDateTime,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.data.success) {
        if (response.data.data.length === 0) {
          // Show a toast if no data is available
          toast({
            title: "No Data",
            description: "No available data for the selected date range.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        } else {
            console.log(response.data);
          // Set report data if available
          setReportData(response.data.data);
          setIsSearchModalOpen(false);
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to fetch the report data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };  

  const downloadCSV = () => {
    // Implement CSV download function (similar to the Report.js component)
  };

  return (
    <ChakraProvider>
      {!reportData.length && (
        <Button colorScheme="blue" onClick={() => setIsSearchModalOpen(true)}>
          Search
        </Button>
      )}

      <Modal isOpen={isSearchModalOpen} onClose={() => setIsSearchModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Affiliate Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>From Date (Only Mondays)</FormLabel>
              <DatePicker
                selected={formData.fromDate}
                onChange={(date) => handleInputChange(date, 'fromDate')}
                filterDate={isMonday}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a Monday"
                showPopperArrow={false}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>To Date (Only Mondays)</FormLabel>
              <DatePicker
                selected={formData.toDate}
                onChange={(date) => handleInputChange(date, 'toDate')}
                filterDate={isMonday}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a Monday"
                showPopperArrow={false}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Generate
            </Button>
            <Button onClick={() => setIsSearchModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box overflowX="auto">
        <Table variant="striped" colorScheme="teal" size="sm">
          <Thead bg="gray.200">
            <Tr>
              <Th>Parent Name</Th>
              <Th>Total Rakeback Earnings</Th>
              <Th>Total Lossback Earnings</Th>
              <Th>Rakeback Claimed</Th>
              <Th>Lossback Claimed</Th>
              <Th>Downline UserID</Th>
              <Th>Downline Earnings</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reportData.map((item, index) => (
                <Tr key={index}>
                <Td>{item.parentName}</Td>
                <Td>{parseFloat(item.totalRakebackEarnings).toFixed(2)}</Td>
                <Td>{parseFloat(item.totalLossbackEarnings).toFixed(2)}</Td>
                <Td>{item.isRakebackClaimed === 'YES' ? 'YES' : 'NO'}</Td>
                <Td>{item.isLossbackClaimed === 'YES' ? 'YES' : 'NO'}</Td>
                <Td>{item.downlineUserId}</Td>
                <Td>{parseFloat(item.downlineEarnings).toFixed(2)}</Td>
                </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {reportData.length > 0 && (
        <Button colorScheme="green" onClick={downloadCSV}>
          Download CSV
        </Button>
      )}
    </ChakraProvider>
  );
}

export default AffiliateReport;
