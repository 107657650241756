import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Stack,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

export default function VIPLevelModal(props) {
    const { isOpen, onClose, mode, levelData, onSubmit } = props;
    const [levelName, setLevelName] = useState("");
    const [minXP, setMinXP] = useState("");
    const [maxXP, setMaxXP] = useState("");
    const [levelIcon, setLevelIcon] = useState("");
    const [casinoPoints, setCasinoPoints] = useState("0");
    const [sportsPoints, setSportsPoints] = useState("0");
    const toast = useToast();

    // Populate form fields if editing
    useEffect(() => {
        if (mode === "edit" && levelData) {
            setLevelName(levelData.levelName);
            setMinXP(levelData.minXP);
            setMaxXP(levelData.maxXP);
            setLevelIcon(levelData.levelIcon);
            setCasinoPoints(levelData.casinoPoints);
            setSportsPoints(levelData.sportsPoints);
        } else {
            // Clear the form if adding a new level
            setLevelName("");
            setMinXP("");
            setMaxXP("");
            setLevelIcon("");
            setCasinoPoints(0);
            setSportsPoints(0);
        }
    }, [mode, levelData]);

    // Handle form submission for both add and edit modes
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!levelName || !minXP || !maxXP || !casinoPoints || !sportsPoints) {
            toast({
                title: "Error",
                description: "Please fill in all required fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            levelName,
            minXP: parseFloat(minXP),
            maxXP: parseFloat(maxXP),
            levelIcon,
            casinoPoints: parseFloat(casinoPoints),
            sportsPoints: parseFloat(sportsPoints),
        };

        // Call onSubmit prop to handle the logic (could be an API call)
        onSubmit(payload, mode === "edit" ? levelData.levelId : null);

        // Close the modal after submission
        onClose();
    };

    return (
        <>
            <Modal
                onClose={() => onClose(false)}
                isOpen={isOpen}
                isCentered
                size={"xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {mode === "add" ? "Add Level" : "Edit Level"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Level Name</FormLabel>
                                    <Input
                                        value={levelName}
                                        onChange={(e) =>
                                            setLevelName(e.target.value)
                                        }
                                        placeholder="Enter level name"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Minimum XP</FormLabel>
                                    <Input
                                        type="number"
                                        value={minXP}
                                        onChange={(e) =>
                                            setMinXP(e.target.value)
                                        }
                                        placeholder="Enter minimum XP"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Maximum XP</FormLabel>
                                    <Input
                                        type="number"
                                        value={maxXP}
                                        onChange={(e) =>
                                            setMaxXP(e.target.value)
                                        }
                                        placeholder="Enter maximum XP"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Casino Points</FormLabel>
                                    <Input
                                        value={casinoPoints}
                                        onChange={(e) =>
                                            setCasinoPoints(e.target.value)
                                        }
                                        placeholder="Enter Casino Points"
                                    />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Sports Book Points</FormLabel>
                                    <Input
                                        value={sportsPoints}
                                        onChange={(e) =>
                                            setSportsPoints(e.target.value)
                                        }
                                        placeholder="Enter Sports Book Points"
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Level Icon URL</FormLabel>
                                    <Input
                                        value={levelIcon}
                                        onChange={(e) =>
                                            setLevelIcon(e.target.value)
                                        }
                                        placeholder="Enter level icon URL"
                                    />
                                </FormControl>
                            </Stack>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Stack spacing={4} direction="row">
                            <Button
                                width={"fit-content"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                onClick={() => onClose(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                width={"fit-content"}
                                colorScheme="green"
                                mr={3}
                                onClick={handleSubmit}
                                leftIcon={<CheckIcon boxSize={3} />}
                            >
                                {mode === "add" ? "Add Level" : "Save Changes"}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}