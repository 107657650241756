import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Switch, Box, Table, Thead, Tbody, Tr, Th, Td, Select } from '@chakra-ui/react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './SideNavigationMenu.css';

function SideNavigationMenu() {
    const [sideMenus, setSideMenus] = useState([]);
    const [containers, setContainers] = useState([]);
    const [editingContainer, setEditingContainer] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [activeDeactiveMenu, setActiveDeactiveMenu] = useState(null);
    const [isAddContainerModalOpen, setIsAddContainerModalOpen] = useState(false);
    const [newContainerName, setNewContainerName] = useState('');
    const toast = useToast();

    useEffect(() => {
        fetchSideMenus();
        fetchContainers();
    }, []);

    const fetchSideMenus = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-side-menus`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setSideMenus(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch side menus.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching side menus",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchContainers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-containers`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setContainers(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch containers.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching containers",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleActivateDeactivateClick = (menu) => {
        setActiveDeactiveMenu(menu);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleSubmitActivateDeactivateMenu = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/side-menu/activate-deactivate/${activeDeactiveMenu.sideMenuId}`, {
                isActive: !activeDeactiveMenu.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchSideMenus();
            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Side menu ${activeDeactiveMenu.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating side menu",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedMenus = Array.from(sideMenus);
        const [reorderedItem] = reorderedMenus.splice(result.source.index, 1);
        reorderedMenus.splice(result.destination.index, 0, reorderedItem);

        setSideMenus(reorderedMenus);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/side-menu/update-rankings`, {
                menus: reorderedMenus.map((menu, index) => ({ sideMenuId: menu.sideMenuId, ranking: index }))
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast({
                title: "Side menu rankings updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating rankings",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleContainerChange = async (menu, newContainerId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-side-menu-container/${menu.sideMenuId}`, {
                containerId: newContainerId,
                ranking: menu.ranking, // Keep the ranking as is for now
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchSideMenus();
            toast({
                title: "Side menu container updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating container",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleOnContainerDragEnd = async (result) => {
        if (!result.destination) return;
    
        const reorderedContainers = Array.from(containers);
        const [reorderedItem] = reorderedContainers.splice(result.source.index, 1);
        reorderedContainers.splice(result.destination.index, 0, reorderedItem);
    
        setContainers(reorderedContainers);
    
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-container-rankings`, {
                containers: reorderedContainers.map((container, index) => ({
                    containerId: container.containerId,
                    ranking: index,
                })),
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast({
                title: "Container rankings updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating container rankings.",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    

    const handleToggleShowContainerName = async (container) => {
        try {
            const updatedShowContainerName = !container.showContainerName; // Toggle the current value
    
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-container-show-name/${container.containerId}`, {
                showContainerName: updatedShowContainerName,
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            // Update the containers in the state after successful update
            setContainers(containers.map((c) => 
                c.containerId === container.containerId ? { ...c, showContainerName: updatedShowContainerName } : c
            ));
    
            toast({
                title: `Container visibility ${updatedShowContainerName ? "enabled" : "disabled"}.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating container visibility",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    
    
    const handleToggleShowContainerForSports = async (container) => {
        try {
            const updatedShowContainerForSports = !container.showContainerForSports; // Toggle the current value
    
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-container-show-sports/${container.containerId}`, {
                showContainerForSports: updatedShowContainerForSports,
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            // Update the containers in the state after successful update
            setContainers(containers.map((c) =>
                c.containerId === container.containerId ? { ...c, showContainerForSports: updatedShowContainerForSports } : c
            ));
    
            toast({
                title: `Container ${updatedShowContainerForSports ? "enabled" : "disabled"} for sports successfully.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating container visibility for sports",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    

    const handleEditContainerClick = (container) => {
        setEditingContainer(container);
        setIsEditModalOpen(true);
    };

    const handleSaveContainerChanges = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-container-name/${editingContainer.containerId}`, {
                containerName: editingContainer.containerName,
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            // Update the containers in the state with the new name
            setContainers(containers.map((c) => 
                c.containerId === editingContainer.containerId ? { ...c, containerName: editingContainer.containerName } : c
            ));
    
            toast({
                title: "Container name updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
    
            // Close the modal
            setIsEditModalOpen(false);
        } catch (err) {
            toast({
                title: "Error updating container name",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleAddContainerClick = () => setIsAddContainerModalOpen(true);

    const handleSubmitAddContainer = async () => {
        if (!newContainerName) {
            toast({
                title: "Container name is required.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/add-container`, {
                containerName: newContainerName,
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Close the modal and clear the input
            setIsAddContainerModalOpen(false);
            setNewContainerName('');

            // Fetch updated container list
            fetchContainers();

            toast({
                title: "Container added successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error adding container",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDeleteContainer = async (containerId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/delete-container/${containerId}`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            // Fetch updated container list and side menu list
            fetchContainers();
            fetchSideMenus();
    
            toast({
                title: "Container deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error deleting container",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <h2>CONTAINERS</h2>
                <Button colorScheme="blue" onClick={handleAddContainerClick} size="sm" ml={2}>
                    Add Container
                </Button>
                <DragDropContext onDragEnd={handleOnContainerDragEnd}>
                    <Droppable droppableId="containers">
                        {(provided) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef} variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Container Name</Th>
                                        <Th>Ranking</Th>
                                        <Th>Show Container Name</Th>
                                        <Th>Show Container For Sports</Th>
                                        <Th>Edit</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {containers.map((container, index) => (
                                        <Draggable key={container.containerId} draggableId={container.containerId.toString()} index={index}>
                                            {(provided) => (
                                                <Tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                    <Td>{container.containerName}</Td>
                                                    <Td>{index + 1}</Td>
                                                    <Td>
                                                        <Switch
                                                            isChecked={container.showContainerName}
                                                            onChange={() => handleToggleShowContainerName(container)}
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Switch
                                                            isChecked={container.showContainerForSports}
                                                            onChange={() => handleToggleShowContainerForSports(container)}
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Button onClick={() => handleEditContainerClick(container)}>Edit</Button>
                                                    </Td>
                                                    <Td>
                                                        <Button colorScheme="red" onClick={() => handleDeleteContainer(container.containerId)}>Delete</Button>
                                                    </Td>
                                                </Tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Tbody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>

                <br></br>
                <h2>SIDE MENU ITEMS</h2>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="sideMenus">
                        {(provided) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef} variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Menu Name</Th>
                                        <Th>Container</Th>
                                        <Th>Ranking</Th>
                                        <Th>Is Active</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {sideMenus
                                        .filter((menu) => menu.sideMenuName.toLowerCase().includes(searchQuery))
                                        .map((menu, index) => (
                                            <Draggable key={menu.sideMenuId} draggableId={menu.sideMenuId.toString()} index={index}>
                                                {(provided) => (
                                                    <Tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <Td>{menu.sideMenuName}</Td>
                                                        <Td>
                                                        <Select
                                                            value={menu.containerId !== null ? menu.containerId.toString() : ''} // Ensure value is always a string for the dropdown
                                                            onChange={(e) => handleContainerChange(menu, e.target.value === '' ? null : parseInt(e.target.value))} // Parse to integer if not null
                                                        >
                                                            <option value="">None</option> {/* Option for NULL */}
                                                            {containers.map((container) => (
                                                                <option key={container.containerId} value={container.containerId.toString()}>
                                                                    {container.containerName}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                        </Td>
                                                        <Td>{index + 1}</Td>
                                                        <Td>
                                                            <Switch
                                                                isChecked={menu.isActive}
                                                                onChange={() => handleActivateDeactivateClick(menu)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </Tbody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* Activate/Deactivate Side Menu Modal */}
                <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{activeDeactiveMenu?.isActive ? "Deactivate" : "Activate"} Side Menu</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {activeDeactiveMenu?.isActive ? "deactivate" : "activate"} this side menu?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateMenu}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Add container Modal */}
                <Modal isOpen={isAddContainerModalOpen} onClose={() => setIsAddContainerModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add New Container</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel>Container Name</FormLabel>
                                <Input
                                    value={newContainerName}
                                    onChange={(e) => setNewContainerName(e.target.value)}
                                    placeholder="Enter container name"
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitAddContainer}>
                                Add
                            </Button>
                            <Button onClick={() => setIsAddContainerModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit container name Modal */}
                <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Container Name</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel>Container Name</FormLabel>
                                <Input
                                    value={editingContainer?.containerName || ''}
                                    onChange={(e) =>
                                        setEditingContainer({
                                            ...editingContainer,
                                            containerName: e.target.value,
                                        })
                                    }
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSaveContainerChanges}>
                                Save
                            </Button>
                            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default SideNavigationMenu;
