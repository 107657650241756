// src/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Sidebar.css';

function Sidebar() {
  const { logout } = useAuth(); 
  return (
    <div className="sidebar">
      <nav>
        <h2>Sidebar</h2>
        <ul>
          <li><Link to="/users">Users</Link></li>
          <li><Link to="/quests">Quests</Link></li>
          <li><Link to="/currency">Multi-Currency</Link></li>
          <li><Link to="/report">Report</Link></li>
          <li><Link to="/maintenance">Maintenance</Link></li>
          <li><Link to="/threshold">Threshold</Link></li>
          <li><Link to="/vip-levels">VIP Levels</Link></li>
          {/* Additional links */}
        </ul>
        <br></br>
        <h2>Game Managment</h2>
        <ul>
          <li><Link to="/gameProviders">Game Providers</Link></li>
          <li><Link to="/allGames">All Games</Link></li>
          <li><Link to="/sideNavigationMenu">Side Menu</Link></li>
          <li><Link to="/homeCategoryOrder">Home Category Order</Link></li>
          <li><Link to="/homeListingOrder">Home Layout Order</Link></li>
          <li><Link to="/homeBanner">Home Banner</Link></li>
        </ul>
        <br></br>
        <h2>Withdrawal Management</h2>
        <ul>
          <li><Link to="/withdrawalHistory">Withdrawal History</Link></li>
        </ul>
        <br></br>
        <h2>Affilate Managment</h2>
        <ul>
          <li><Link to="/affiliate">Affiliates</Link></li>
          <li><Link to="/affiliateReport">Report</Link></li>
        </ul>
      </nav>
      <button onClick={logout}>Logout</button>
    </div>
  );
}

export default Sidebar;
